import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Balanced Brackets Problem HackerRank",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:56:08.000Z",
  "url": "/2018/02/balanced-brackets-problem-hackerrank/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["Exercises"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Nice problem for that is used on all of our IDEs`}</p>
    <p>{`simply checking if our brackets fit together.`}</p>
    <p>{`Problems description`}{` `}<a parentName="p" {...{
        "href": "https://www.hackerrank.com/challenges/ctci-balanced-brackets/problem"
      }}>{`here`}</a></p>
    <h6>{`Solution Brief: {#solutionbrief}`}</h6>
    <p>{`Simply running through each char in the string, checking if its a bracket or not. On our case, it always brackets.`}</p>
    <p>{`Now if it`}{`’`}{`s an opening bracket push it into our stack.`}</p>
    <p>{`If it`}{`’`}{`s a closing bracket, make sure stack is not empty, cause if so the string is not balanced.`}</p>
    <p>{`Pop the last bracket from the stack and make sure they are balanced together.`}</p>
    <h6>{`Pseudo Code: {#pseudocode}`}</h6>
    <pre><code parentName="pre" {...{}}>{`stack  
for c in str  
   if opener, push to stack, next loop

   if closer
      if stack is empty, NOT balanced

      pop last item from stack
      compare the 2,
      if not brothers, NOT balanced

if stack is empty  
   its balanced
else  
   its not
`}</code></pre>
    <h6>{`Solution Code: {#solutioncode}`}</h6>
    <p><a parentName="p" {...{
        "href": "https://github.com/tzookb/hackerrank/blob/master/cracking_interview/ctci-balanced-brackets/sol.py"
      }}>{`Github Src`}</a></p>
    <pre><code parentName="pre" {...{}}>{`def isCloser(char):  
    closers = ['}', ']', ')']
    return char in closers

def isOpener(char):  
    openers = ['{', '[', '(']
    return char in openers

def isBrothers(opener, closer):  
    return (opener=='{' and closer=='}') or (opener=='[' and closer==']') or (opener=='(' and closer==')')

def is_matched(str):  
    stack = []
    for char in str:
        if isOpener(char):
            stack.append(char)
            continue
        if isCloser(char):
            if (len(stack)==0):
                return False
            prev = stack.pop()
            if not isBrothers(prev, char):
                return False
    return len(stack) == 0

res = is_matched('{[()](})')  
print res
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      